.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

a {
  color: $link_color;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    color: lighten($link_color, 15%);
    text-decoration: underline;
  }
}
