article#resume-projects-software {
  // .row.timepoint {
  //   .timelines {
  //     .timepoint_start,
  //     .timepoint_end {
  //       .timeline_project {
  //         font-size: 10px;
  //         background-color: $blue;
  //         &.project_id_0 {
  //           visibility: hidden;
  //         }
  //         &.project_id_37 {
  //           background-color: $orange;
  //         }
  //         &.project_id_1,
  //         &.project_id_2,
  //         &.project_id_3,
  //         &.project_id_4,
  //         &.project_id_5, {
  //           background-color: $purple;
  //         }
  //       }
  //     }
  //   }
  // }
  section.project {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: 10rem auto 10rem;
    margin-bottom: 0.75rem;
    grid-column-gap: 1rem;
    line-height: 1.25;

    .title,
    .description,
    .skills {
      grid-column: 2 / 3;
    }

    .title {
      font-size: 1.7rem;
      line-height: 1rem;
      grid-row: 1;
    }

    .period {
      order: initial;
      font-size: 1rem;
      margin-bottom: 0;
      grid-column: 1;
      grid-row: 1;
      align-self: end;
      margin-bottom: 0.6rem;
    }

    .description {
      grid-row: 2;
      min-height: 1.5rem;
      h1, h2, h3, h4 {
        grid-column: 2 / span 2;
      }
    }

    .skills {
      grid-row: 3;
    }
  }

  section.project:not(:last-child) {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $kbg2;
  }
}

@media (min-width: $res_treshold_small) {
  .project_group {
    border: 2px solid $kfg2;
    padding: 0.2rem;
  }

  article#resume-projects {
    section.project {

      p, ul {
        line-height: 1.3;

      }
      margin-bottom: 0.75rem;

      &:not(:last-child), {
        border-bottom: none;
      }

      .description {
        // min-height: 1.5rem;
      }
    }
  }
}

@media (min-width: $res_treshold_big) {
}
