@media print {
  @page {
    margin: 1cm 0.5cm;
  }

  .job {
    page-break-inside: avoid;
  }

  body {
  a {
    &:hover,
    &:visited,
    &:active,
    &:not(.btn) {
      text-decoration: none;
      color: black;
    }
  }
  }

  #page-top {
    padding: 0;
  }

  nav#sideNav {
    display: none;
  }

  .cv-content {
    position: relative;

    #markdown_content {
      display: block;
      position: relative;
      page-break-after: always;

      .cv_profile_pic {
        display: block;
      }
    }

    #side {
      display: block;
      > section  {
        page-break-inside: avoid;
      }

      #education {
        padding-top: 1rem;
      }

      #skills {
        // page-break-inside: avoid;
      }

      #picture {
        display: none;
      }

      #contact {
        // page-break-inside: avoid;
      }
    }
  }
}
