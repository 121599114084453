section.resume-section {
  color: $gray-500;
  //border-bottom: 1px solid $gray-300;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  .resume-item {
    .resume-content {}
    .resume-date {
      min-width: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      color: inherit;
  }

  li {
      .highlight {
          margin: 0;
    }
  }

  .post_excerpt {
    font-size: 90%;

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    ul {
        margin: 0;
    }
  }
}

@media (min-width: $res_treshold_small) {
  section.resume-section {
    min-height: 100vh;
    .resume-item {
      .resume-content {}
      .resume-date {
        min-width: 18rem;
      }
      .resume-last-modified-date {
          font-size: 80%;
      }
    }
  }
}

@media (min-width: $res_treshold_big) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
