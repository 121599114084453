// Variables

// Restate Bootstrap Variables

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #BD5D38 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$kbg1:      #062437 !default;
$kbg2:      #244A61 !default;
$kfg1:      #E8EAEC !default;
$kfg2:      #7B96A6 !default;

$kc1:       #563105 !default;
$kc2:       #FFE0B9 !default;

$primary:       $kfg2 !default;
$secondary:     $kc2 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$background:    $kbg1 !default;

$link_color:          #4782b8 !default;
$kaaos_main: $orange;
// Page Variables
$sidebar-base-width: 17rem;

// Font sizes
$h1_size: 2.5rem;
$h2_size: 1.7rem;
$h3_size: 1.3rem;
$h4_size: 1.05rem;
$h5_size: 1rem;
$h6_size: 0.8rem;

//
$res_treshold_small: 768px;
$res_treshold_big: 992px;
