// Mixins
// Bootstrap Button Variant
@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@mixin body-font {
  // font-family: 'Open Sans', sans-serif;
}
@mixin heading-font {
  // font-family: 'Saira Extra Condensed', serif;
}
