// Global styling for this template

body {
  @include body-font;
  padding-top: 54px;
  color: $gray-600;
  word-break: break-word;
}
@media (min-width: $res_treshold_big) {
  body {
    padding-top: 0;
    padding-left: $sidebar-base-width;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray-800;
}

h1 {
    font-size: $h1_size;
}

h2 {
    font-size: $h2_size;
}

h3 {
    font-size: $h3_size;
}

h4 {
    font-size: $h4_size;
}

h5 {
    font-size: $h5_size;
}

h6 {
    font-size: $h6_size;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  @include heading-font;
  font-size: 1.35rem;
}

.list-social-icons {
  a {
    color: $gray-700;
    &:hover {
      color: $primary;
    }
    .fa-lg {
      font-size: 1.75rem;
    }
  }
}

.list-icons {
  font-size: 3rem;
  .list-inline-item i {
    &:hover {
      color: $primary;
    }
  }
}
