// Core variables and mixins
@import "resume/variables.scss";
@import "resume/mixins.scss";
// Global CSS
@import "resume/global.scss";
@import "resume/nav.scss";
@import "resume/resume-item.scss";
@import "resume/post.scss";
@import "resume/cv.scss";
@import "resume/projects.scss";
@import "resume/bootstrap-overrides.scss";
@import "resume/syntax-highlighting";
@import "resume/print.scss";

// Components
body {
    background-color: $background;
}

a {
    &.alternative {
        color: $secondary;
        &:hover,
        &:focus,
        &:active {
            color: darken($secondary, 15%);
        }
    }
    &.nav-link {
        text-decoration: none;
    }
    &.simple {
        text-decoration: none;
    }
    h1,
    h2,
    h3,
    h4 {
        color: $link-color;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $kfg1;
}

.vjs-resolution-button .vjs-button {
    width: initial;
    height: initial;
}

blockquote {
    font-family: monospace;
    font-size: smaller;
    line-height: normal;
    background-color: lighten($background, 5%);
    padding: 1em;
}

blockquote p {
    margin: 0;
}

#kaaoslogo {
  height: 1em;
  display: inline-block;
  position: relative;
  top: -1.12em;
  left: -0.01em;
}

.stealth {
  display: none;
}
