.container {
  max-width: $res_treshold_small - 40px;
}

article#cv {
  .ul_icon {
    height: 16px;
    width: 16px;
  }

  .cv-content {
    display: flex;
    flex-direction: column;

    p, ul {
      line-height: 1.3;
    }

    #markdown_content,
    #work-experience,
    #work-experience > div.job:not(:last-child),
    #side > section:not(:last-child), {
      border-bottom: 1px solid $kbg2;
    }

    img.cv_profile_pic {
      float: right;
      margin-left: 0.5rem;
    }

    #side > section {
      margin-top: 1rem;
    }

    #contact ul,
    #skills ul,
    #links ul {
      padding: 0;
      list-style: none;
      li {
        p {
          margin: 0;
        }
      }
    }

    .location {
      font-style: italic;
    }

    #work-experience {
      margin-top: 1rem;
      .job {
        display: flex;
        flex-flow: row wrap;
        margin-top: 0.5rem;

        > * {
          flex: 1 100%;
        }

        .title {
          order: 1;
          h3,h4 {
            margin-bottom: 0;
          }
        }

        .company {
          flex: 1 50%;
          order: 10;
        }

        .location {
          flex: 1 50%;
          order: 20;
          text-align: right;

        }

        .description {
          order: 30;

          p:last-child,
          ul:last-child {
            margin-bottom: 0;
          }
          h1,h2,h3,h4,h5,h6 {
            margin-bottom: 0;
          }
        }

        .period {
          order: 40;
          font-size: 0.75rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    #side {
      display: flex;
      flex-direction: column;

      #picture {
        display: none;
        order: 0;
      }

      #contact {
        order: 15;
        ul li p {
          display: inline;
        }
      }

      #links {
        order: 20;
      }

      #skills {
        order: 5;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;

        h2 {
          width: 100%;
        }

        section {
          margin-right: 1rem;
          flex: 1 1 30%;
          min-width: 10rem;
        }
      }

      #education {
        order: 10;

        display: flex;
        flex-flow: row wrap;

        h2 {
          order: 0;
          flex: 1 100%;
        }

        .title {
          order: 1;
          flex: 1 100%;
        }

        .facility {
          order: 2;
          flex: 1 100%;
        }

        .location {
          order: 4;
          text-align: right;
        }

        .period {
          order: 3;
          flex-grow: 1;

        }

        .thesis {
          order: 5;
          h6 {
            margin: 0.2rem 0;
          }
        }
      }

      #last_updated {
        order: 25;
        font-size: 0.75rem;
      }
    }
  }
}

@media (min-width: $res_treshold_small) {
  .container {
    max-width: $res_treshold_big - 40px;
  }

  article#cv .cv-content {
    display: grid;
    grid-template-columns: auto 30%;
    grid-auto-flow: dense;

    #markdown_content,
    #work-experience,
    #work-experience > div.job:not(:last-child),
    #side > section:not(:last-child) {
      border-bottom: none;
    }

    h1, h2, h3, h4 {
      grid-column: 1;
    }

    #markdown_content {
      .cv_profile_pic {
        display: none;
      }
    }

    #markdown_content,
    #work-experience {
      grid-column: 1;
      padding-right: 0.75rem;
    }

    #work-experience {
      .job {
        display: grid;
        grid-auto-flow: dense;
        grid-template-columns: 10rem auto 10rem;
        margin-bottom: 0.75rem;

        .title {
          font-size: 1.7rem;
          line-height: 1rem;
          grid-column: 1 / span 3;
          grid-row: 1;
        }

        .period {
          order: initial;
          font-size: 1rem;
          margin-bottom: 0;
        }

        .company {
            grid-column: 2;
            min-width: 10rem;
        }

        .location {
          grid-column: 3;
          text-align: right;
        }

        .description {
          grid-column: 2 / span 2;
          min-height: 1.5rem;

          h1, h2, h3, h4 {
            grid-column: 2 / span 2;
          }
        }
      }
    }

    #side {
      grid-column: 2;
      grid-row: 1 / 4;
      display: flex;
      flex-direction: column;

      #picture,
      #contact,
      #links,
      #skills,
      #education,
      #last_updated {
        border-left: 1px solid $kbg2;
        padding-left: 0.75rem;
        margin: 0.5rem 0;
      }

      #picture {
        display: block;
        .cv_profile_pic {
          display: block;
          float: none;
          margin: 0;
        }
      }

      #contact {
        order: 5;
      }

      #links {
        order: 20;
      }

      #skills {
        order: 10;
        display: block;
      }

      #last_updated {
        order: 25;
      }

      #education {
        display: flex;
        flex-flow: row wrap;
        order: 15;

        h2 {
          order: 0;
        }

        .title {
          order: 1;
          flex: 2 auto;
        }

        .period {
          order: 3;
          flex: 1 auto;

        }

        .facility {
          order: 2;
        }

        .location {
          order: 4;
          text-align: right;
        }

        .thesis {
          order: 5;
        }
      }
    }
  }
}

@media (min-width: $res_treshold_big) {
  article#cv  .cv-content {
    #side {
      #picture {
        display: none;
      }
    }
  }
}
